import $ from 'jquery'
import 'select2/dist/css/select2.css'
import 'select2'

// $('body').on('DOMNodeInserted', 'select', function () {
//     $(this).select2();
// });
$( document ).on('turbolinks:load', function() {
  $(document).on('select2:open', e => {
    const id = e.target.id;
    const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
    target.focus();
  });

  $('.offline_product_name_search').select2({});

  function ts_select2_products(elem){

    elem.select2({
      ajax: {
        url: "/products/product_name_livesearch",
        method: "GET",
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            product_name: params.term, // search term
            product_level: elem.attr("data-level"), // search term
            product_type: elem.attr("data-ptype"), // search term
          };
        },

        processResults: function (data, params) {
          return {
            results: data.data
          };
        },
        cache: true
      },

      placeholder: 'Select a Product',
      minimumInputLength: 1,
      templateResult: formatRepo,
      templateSelection: formatRepoSelection
    });
  }

  var elems = $(".product_name_search")
  elems.each(function(){
    console.log("lem atr", $(this).attr("data-level"))
    ts_select2_products($(this));
  })

  $(document).on('click', '.add_fields', function(){
    console.log('add fields clicked')
    var elems = $(".product_name_search")
    elems.each(function(){
      if(!$(this).hasClass('select2-hidden-accessible')){
        ts_select2_products($(this));
      }
    })
  });

  function formatRepo (repo) {
    if (repo.loading) {
      return repo.text;
    }

    var $container = $(
      "<div class='select2-product clearfix'>" +
        "<div class='select2-product_name'></div>" +
      "</div>"
    );

    $container.find(".select2-product_name").text(repo.product_name);
    return $container;
  }

  function formatRepoSelection (repo) {
    console.log('add fields clicked')
    return repo.text;
  }
});
