import $ from 'jquery'

$(document).on('turbolinks:load', function(){

  payacct_selected()

  var field_text = ""
  var field_type = ""
  console.log("toggle.js  file")

  $( ".password_visible" ).bind( "click", function (event) {
    console.log("password visibility function")
    if ($(".password_visible_field")[0].type == 'password') {
      console.log("if condition")
      field_text = 'visibility_on'
      field_type = 'text'
    }
    else
    {
      console.log("else condition")
      field_type = 'password'
      field_text = 'visibility_off'
    }
    console.log("field_type", field_type)

    // $(".password_visible")[0].innerHTML = field_text
    $(".password_visible_field").removeAttr("type").prop('type', field_type);
    // $(".password_visible")[1].innerHTML = field_text
  });

  // $( "#company_setting_payacct_type" ).bind( "click", function (event) {
  //   var payacct_type = $( "#company_setting_payacct_type" ).val()
  //   console.log("payacct_type ", payacct_type)
  //   if(payacct_type == "Account")
  //   {
  //     console.log("is account")
  //     $('#payacct-product').hide()
  //     $('#payacct-account').show()
  //   }
  //   else
  //   {
  //     console.log("is product")
  //     $('#payacct-account').hide()
  //     $('#payacct-product').show()
  //   }
  // });

})
