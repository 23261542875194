import $ from 'jquery'
import 'select2/dist/css/select2.css'
import 'select2'

$( document ).on('turbolinks:load', function() {
  $(document).on('select2:open', e => {
    const id = e.target.id;
    const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
    target.focus();
  });

  $('.offline_scale_stock_voucher_number_search').select2({});

  function ts_select2_scale_stocks(elem){
    console.log("adas ",elem)
    elem.select2({
      ajax: {
        url: "/scale_stocks/scale_stock_voucher_number_livesearch",
        method: "GET",
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            voucher_number: params.term // search term
          };
        },

        processResults: function (data, params) {
          return {
            results: data.data
          };
        },
        cache: true
      },

      placeholder: 'Select a voucher_number',
      minimumInputLength: 1,
      templateResult: formatRepo,
      templateSelection: formatRepoSelection
    });
  }


  var elems = $(".scale_stock_voucher_number_search")
  elems.each(function(){
    console.log("lem atr", $(this).attr("data-level"))
    ts_select2_scale_stocks($(this));
  })

  function formatRepo (repo) {
    if (repo.loading) {
      return repo.text;
    }

    var $container = $(
      "<div class='select2-scale_stock clearfix'>" +
        "<div class='select2-voucher_number'></div>" +
      "</div>"
    );

    $container.find(".select2-voucher_number").text(repo.voucher_number);
    return $container;
  }

  function formatRepoSelection (repo) {
    console.log("repo", repo.id)
    return repo.text;
  }
});
