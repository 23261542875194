// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "../search_accounts"
import "../search_brands"
import "../search_categories"
import "../search_colours"
import "../search_guarantees"
import "../search_outlets"
import "../search_prods"
import "../search_sizes"
import "../search_products"
import "../search_products2"
import "../search_employee"
import "../search_orders"
import "../search_scale_stocks"
import "./toggle";
Rails.start()
Turbolinks.start()
ActiveStorage.start()
require("channels")
require("jquery")
require('./nested-forms/addFields')
require('./nested-forms/removeFields')
require('./amount-calculate/stock_calculate')
