import $ from 'jquery'
class removeFields {
  // This executes when the function is instantiated.
  constructor() {
    this.iterateLinks()

    // $(document).on('click', ".remove_fields", function(){
    //   console.log('removeFields clicked jqury');

    // });
  }

  iterateLinks() {
    // Use event delegation to ensure any fields added after the page loads are captured.
    document.addEventListener('click', e => {

    // e.target.closest('.remove_fields')
      console.log(e.target.className)
      if (e.target && (e.target.className == 'bx bx-trash' || e.target.className == "remove_fields")) {
        console.log("iterateLinks ", e)
        console.log("iterateLinks e target ", e.target)
        this.handleClick(e.target, e)
      }
    })
  }

  handleClick(link, e) {
    console.log('trying to remove')
    // if($('.perform-remove').length < 2) {
      // console.log('last item');
      // return;
    // }

    // Stop the function from executing if a link or event were not passed into the function.
    if (!link || !e) return
    // Prevent the browser from following the URL.

  console.log("link", link)
    e.preventDefault()
    // Find the parent wrapper for the set of nested fields.
    let fieldParent = link.closest('.perform-remove')
  console.log("link closest", link.closest)
  console.log("fieldParent: ", fieldParent)
    // If there is a parent wrapper, find the hidden delete field.
    let deleteField = fieldParent
      ? fieldParent.querySelector('.trash input[type="hidden"]')
      : null
    // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
    if (deleteField) {
      deleteField.value = 1
      fieldParent.style.display= 'none';//
      fieldParent.querySelector('[required]').removeAttribute("required")

      // fieldParent.remove();
      // total_price();
    }
  }
}

// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
window.addEventListener('turbolinks:load', () => new removeFields())
