import $ from 'jquery'
import 'select2/dist/css/select2.css'
import 'select2'

// $('body').on('DOMNodeInserted', 'select', function () {
//     $(this).select2();
// });
$( document ).on('turbolinks:load', function() {
  $(document).on('select2:open', e => {
    const id = e.target.id;
    const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
    target.focus();
  });

  $('.offline_brand_name_search').select2({});


    // $("#stock_order_id").select2()

  function ts_select2_brands(elem){
    console.log("adas ",elem)
    elem.select2({
      ajax: {
        url: "/brands/brand_name_livesearch",
        method: "GET",
        dataType: 'json',
        delay: 250,
        data: function (params) {
          console.log(elem.attr("data-level"))
          console.log(elem.attr("data-option"))
          console.log(elem.attr("data-type"))

          return {
            name: params.term, // search term
          };
        },

        processResults: function (data, params) {
          return {
            results: data.data
          };
        },
        cache: true
      },

      placeholder: 'Select a Brand',
      minimumInputLength: 1,
      templateResult: formatRepo,
      templateSelection: formatRepoSelection
    });
  }

  $(document).on('click', '.add_fields', function(){
    console.log('add fields clicked')
    var elems = $(".brand_name_search")
    elems.each(function(){
      if(!$(this).hasClass('select2-hidden-accessible')){
        ts_select2_brands($(this));
      }
    })
  });


  var elems = $(".brand_name_search")
  elems.each(function(){
    console.log("aa lem atr", $(this).attr("data-level"))
    ts_select2_brands($(this));
  })

  function formatRepo (repo) {
    if (repo.loading) {
      return repo.text;
    }

    var $container = $(
      "<div class='select2-account clearfix'>" +
        "<div class='select2-name'></div>" +
      "</div>"
    );

    $container.find(".select2-name").text(repo.name);
    return $container;
  }

  function formatRepoSelection (repo) {
    console.log("repo", repo.id)
    return repo.text;
  }
});
